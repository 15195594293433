/* eslint-disable camelcase */
import { useContext, createContext } from 'react';

const context = createContext<{
  [id: string]: {
    slug: string;
    name: string;
    uuid: string;
    real_path: string;
  };
}>({});

export const LinkProvider = ({ value, children }) => {
  return <context.Provider value={value}>{children}</context.Provider>;
};

export default function useLinks() {
  const links = useContext(context);
  if (!links) throw Error('Cannot retrieve links outside of provider');
  return links;
}
