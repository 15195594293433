import { FC } from 'react';

import { useColorModeValue } from '@chakra-ui/color-mode';
import { ChakraComponent, Stack } from '@chakra-ui/react';
import { fsEvent } from '@/services/fullstory';

import { Button } from '@/elements';
import { Cta as TCta } from '@/types.d';

import SLink from '../Link';
import useModals from '@/lib/providers/modalProvider';

const Cta: FC<{
  variant?: string;
  content: TCta;
  Component?: ChakraComponent<any>;
}> = ({ content, Component = Button as any, ...rest }) => (
  <SLink
    Component={Component}
    link={content.link}
    target={content.newTab ? '__blank' : undefined}
    trackName={content.trackName}
    {...rest}
  >
    {content.text}
  </SLink>
);

const getLightVariant = index => (index === 0 ? 'dark' : 'light') as string;
const getDarkVariant = index => (index === 0 ? 'light' : 'dark') as string;

export const CtaRow: FC<{
  ctas: Array<TCta>;
  direction?: any;
  variant?: string;
  Component?: ChakraComponent<any>;
}> = ({ ctas, direction = 'row', variant = 'light', Component }) => {
  let getVariant = useColorModeValue(getDarkVariant, getLightVariant);
  const { onOpen: openModal } = useModals();

  const onCTAClick = text => {
    fsEvent({ name: `PAYMENT - ${text}` });
    openModal();
  };

  if (variant === 'light') {
    getVariant = getLightVariant;
  } else if (variant === 'dark') {
    getVariant = getDarkVariant;
  } else if (variant) {
    getVariant = () => variant;
  }

  return (
    <Stack
      alignItems='flex-start'
      className='ctas'
      direction={direction}
      flexWrap='wrap'
      maxW='100%'
      spacing='md'
    >
      {ctas.map(cta => (
        <Button
          size='lg'
          key={cta._uid}
          onClick={() => onCTAClick(cta.text)}
          color='white'
        >
          {cta.text}
        </Button>
      ))}
    </Stack>
  );
};

export default Cta;
