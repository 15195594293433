/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable camelcase */
import { FC } from 'react';

import { ChakraComponent, ChakraProps } from '@chakra-ui/react';

import { A } from '@/elements';
import DynamicLink from '@/lib/DynamicLink';
import useLinks from '@/lib/storyblok/linksContext';
import { Link as TLink } from '@/types.d';

const DLink = DynamicLink as any;

const Link: FC<{
  link: TLink;
  Component?: ChakraComponent<any>;
  variant?: string;
  children: any;
  target?: string;
  trackName?: string;
  trackData?: { [key: string]: any };
} & ChakraProps> = ({
  link: { linktype, url, story, id },
  Component = A as any,
  children,
  ...rest
}) => {
  const links = useLinks();
  // console.log(linktype, url, story, restLink);
  switch (linktype) {
    case 'email':
      return (
        <DLink Component={Component} href={`mailto:${url}`} {...rest}>
          {children}
        </DLink>
      );
    case 'story': {
      const path = story?.url ? `/${story?.url}` : links[id]?.real_path;
      return (
        <DLink Component={Component} href={path} {...rest}>
          {children}
        </DLink>
      );
    }
    default:
      return (
        <DLink Component={Component} href={url} {...rest}>
          {children}
        </DLink>
      );
  }
};

export default Link;
