import { FC } from 'react';

import { Box } from '@chakra-ui/react';
import SbEditable from 'storyblok-react';

import { RtIndent as TRtIndent } from '@/types.d';

import { RichText } from '../../components';

const RtIndent: FC<{
  content: TRtIndent;
}> = ({ content }) => {
  return (
    <SbEditable content={content}>
      <Box my='0.8rem' pl='12px'>
        <RichText>{content.body}</RichText>
      </Box>
    </SbEditable>
  );
};

export default RtIndent;
