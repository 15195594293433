import { createContext, useContext, FC } from 'react';

import { UseDisclosureReturn } from '@chakra-ui/react';

const context = createContext<{
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
}>({
  onOpen: undefined,
  onClose: undefined,
  isOpen: false,
});

export const Provider: FC<{
  modalHandler: UseDisclosureReturn;
  children: any;
}> = ({ modalHandler, children }) => {
  const { isOpen, onOpen, onClose } = modalHandler;

  return (
    <context.Provider value={{ onOpen, onClose, isOpen }}>
      {children}
    </context.Provider>
  );
};

export default function useModals() {
  const modalsContext = useContext(context);
  if (!modalsContext)
    throw new Error('Cannot use Modals context outside of Modals provider');
  return modalsContext;
}
