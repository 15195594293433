import { FC, useCallback } from 'react';

import { ChakraComponent, ChakraProps } from '@chakra-ui/react';
import NextLink from 'next/link';

import { track } from '../GTM';

const DynamicLink: FC<Partial<HTMLAnchorElement> &
  ChakraProps & {
    Component: ChakraComponent<any, any>;
    onClick?: Function;
    trackName?: string;
    trackData?: { [key: string]: any };
  }> = ({
  href,
  target,
  children,
  Component,
  rel = 'noreferrer noopener',
  onClick,
  trackName,
  trackData,
  ...rest
}) => {
  const trackOnClick = useCallback(() => {
    if (trackName) track('click', { name: trackName, ...trackData });
    if (onClick) onClick();
  }, [trackName, trackData, onClick]);

  if (!href) {
    return (
      <Component as='button' onClick={trackOnClick} {...rest}>
        {children}
      </Component>
    );
  }

  if (href.match(/^(https?:)?\/\//)) {
    return (
      <Component
        as='a'
        href={href}
        onClick={trackOnClick}
        rel={rel}
        target={target}
        {...rest}
      >
        {children}
      </Component>
    );
  }
  return (
    <NextLink href={href} passHref>
      <Component as='a' href={href} onClick={trackOnClick} {...rest}>
        {children}
      </Component>
    </NextLink>
  );
};

export default DynamicLink;
