/* eslint-disable react/no-unescaped-entities */
import React, { FC, useState } from 'react';

import {
  useToast,
  useDisclosure,
  VStack,
  Text,
  HStack,
  Button,
  FormControl,
  Input,
} from '@chakra-ui/react';

import { H1 } from '@/elements';
import Modal from '@/lib/Modal';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const SignUpModal: FC<{
  handler: ReturnType<typeof useDisclosure>;
}> = ({ handler: { onClose, isOpen } }) => {
  const [email, setEmail] = useState(null);
  const [isSignupDisabled, setIsSignupDisabled] = useState(true);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const toast = useToast();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsSignupDisabled(
      !EMAIL_REGEX.test(String(e.target.value).toLowerCase())
    );
    setIsEmailInvalid(!EMAIL_REGEX.test(String(e.target.value).toLowerCase()));
  };

  const handleSignUp = async () => {
    try {
      await fetch('/api/signup', { method: 'POST', body: email });
      onClose();
      return toast({
        title: 'Thanks for signing up',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      onClose();
      return toast({
        title: 'Sorry, something went wrong. Try again later.',
        description: `${error}`,
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      footer={
        <HStack mb='lg' justifyContent='center' width='100%'>
          <Button
            size='lg'
            color='white'
            isDisabled={isSignupDisabled}
            onClick={handleSignUp}
          >
            Sign up now
          </Button>
        </HStack>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <VStack px='md' py='lg' spacing='sm' textAlign='center'>
        <H1 mb='lg'>Register your interest</H1>
        <Text color='blackText' mb='lg'>
          Kuinua is in development and will soon be launched. Please leave your
          contact details and we will be in touch with some launch day
          exclusives!
        </Text>
        <FormControl id='email' isInvalid={isEmailInvalid} isRequired>
          <Input
            fontSize='md'
            onChange={handleOnChange}
            placeholder='your-email@example.com'
            type='email'
          />
        </FormControl>
      </VStack>
    </Modal>
  );
};

export default SignUpModal;
