import { FC } from 'react';

import { Box } from '@chakra-ui/react';
import Fade from 'react-reveal/Fade';

// eslint-disable-next-line import/prefer-default-export
export const FadeOnScroll: FC<any> = ({
  children,
  distance = '60px',
  duration = 1000,
  ...rest
}) => (
  <Box>
    <Fade bottom cascade distance={distance} duration={duration} {...rest}>
      {children}
    </Fade>
  </Box>
);
