import { FC } from 'react';

import styled from '@emotion/styled';
import { Richtext } from 'storyblok-js-client';
import {
  render,
  NODE_HEADING,
  MARK_LINK,
  NODE_IMAGE,
  NODE_PARAGRAPH,
  NODE_OL,
  NODE_UL,
  NODE_LI,
} from 'storyblok-rich-text-react-renderer';

import components, {
  defaultBlokResolver,
} from '@/components/storyblok/richText';
import {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  P,
  Text,
  Image,
  Link,
  Ol,
  Ul,
  Li,
} from '@/elements';

const Container = styled.div`
  * + * {
    margin-top: 1rem !important;
  }
`;

const RichText: FC<{
  children: Richtext;
  variant?: string;
}> = ({ children: content, variant }) => (
  <Container>
    {render(content, {
      markResolvers: {
        [MARK_LINK]: (
          children,
          { href, target }: { href: string; target: string }
        ) => {
          return (
            <Link href={href} target={target} variant='simple'>
              {children}
            </Link>
          );
        },
      },
      nodeResolvers: {
        [NODE_HEADING]: (children, { level }) => {
          switch (level) {
            case 1:
              return <H1 variant={variant}>{children}</H1>;
            case 2:
              return <H2 variant={variant}>{children}</H2>;
            case 3:
              return <H3 variant={variant}>{children}</H3>;
            case 4:
              return <H4 variant={variant}>{children}</H4>;
            case 5:
              return <H5 variant={variant}>{children}</H5>;
            case 6:
              return <H6 variant={variant}>{children}</H6>;
            default:
              return <Text variant={variant}>{children}</Text>;
          }
        },
        [NODE_IMAGE]: (children, { src, alt, title }) => (
          <Image alt={alt} src={src} title={title} />
        ),
        [NODE_PARAGRAPH]: children => <P variant={variant}>{children}</P>,
        [NODE_OL]: children => <Ol variant='withPadding'>{children}</Ol>,
        [NODE_UL]: children => <Ul variant='withPadding'>{children}</Ul>,
        [NODE_LI]: children => <Li>{children}</Li>,
      },
      blokResolvers: {
        ...components,
      },
      defaultBlokResolver,
    })}
  </Container>
);

export default RichText;
