import { FC, ReactNode } from 'react';

import {
  Box,
  Modal as ModalContainer,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

const defaultContainer = ({ children: c }) => c;
const defaultContainerProps = {};

const Modal: FC<{
  isOpen: boolean;
  onClose: () => void;
  size?: string;
  header?: ReactNode;
  footer?: ReactNode;
  /**
   * The ContentContainer component is useful for wrapping
   * the header, body and footer, e.g. for when the modal is
   * a form and you want the submit button in the footer.
   */
  ContentContainer?: any;
  contentContainerProps?: any;
}> = ({
  isOpen,
  onClose,
  size = 'sm',
  header,
  children,
  footer,
  ContentContainer = defaultContainer,
  contentContainerProps = defaultContainerProps,
}) => {
  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior='inside'
      size={size}
    >
      <ModalOverlay />
      <ModalContent>
        <Box overflow='auto'>
          <ContentContainer {...contentContainerProps}>
            <ModalCloseButton size='lg' />
            {header && <ModalHeader>{header}</ModalHeader>}
            {children && (
              <ModalBody maxH='calc(100vh - 16rem)'>{children}</ModalBody>
            )}
            {footer && <ModalFooter>{footer}</ModalFooter>}
          </ContentContainer>
        </Box>
      </ModalContent>
    </ModalContainer>
  );
};

export default Modal;
