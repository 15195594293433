import {
  HStack as ChakraHStack,
  VStack as ChakraVStack,
} from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

import { extendComponent } from '../lib/utils/chakra';

const extendVStack = extendComponent(ChakraVStack);

const extendHStack = extendComponent(ChakraHStack);

export const VStack = extendVStack(props => ({
  css: theme => ({
    'h2 + h3': {
      color: mode(
        theme.colors.brand.mediumGrey,
        theme.colors.brand.lightGrey
      )(props),
    },
    'h4 + h2': {
      marginTop: '1rem',
    },
    'h4 + h2 + .ctas': {
      marginTop: '2rem',
    },
    '.cta-section > h4:first-of-type': {
      color: mode(
        theme.colors.brand.mediumGrey,
        theme.colors.brand.lightGrey
      )(props),
    },
  }),
}));

export const HStack = extendHStack();
