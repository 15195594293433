import { FC } from 'react';

import { Box } from '@chakra-ui/react';
import SbEditable from 'storyblok-react';

import { Table, Thead, Tbody, Tr, Th, Td } from '@/elements';
import { RtTable as TRtTable } from '@/types.d';

const RtTable: FC<{
  content: TRtTable;
}> = ({ content }) => {
  return (
    <SbEditable content={content}>
      <Box
        border='1px solid transparent'
        borderColor='brand.lightGrey'
        borderRadius='md'
        boxShadow='md'
        p='md'
      >
        <Table>
          <Thead>
            <Tr>
              {content.table.thead.map(th => (
                <SbEditable key={th._uid} content={th}>
                  <Th>{th.value}</Th>
                </SbEditable>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {content.table.tbody.map(trow => (
              <SbEditable key={trow._uid} content={trow}>
                <Tr>
                  {trow.body.map(tc => (
                    <SbEditable key={tc._uid} content={tc}>
                      <Td>{tc.value}</Td>
                    </SbEditable>
                  ))}
                </Tr>
              </SbEditable>
            ))}
          </Tbody>
        </Table>
      </Box>
    </SbEditable>
  );
};

export default RtTable;
