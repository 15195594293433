import { createComponent } from '@/lib/utils/chakra';

// eslint-disable-next-line import/prefer-default-export
export const Hr = createComponent('hr', {
  baseStyle: {
    w: '100%',
    borderColor: 'brand.mediumGrey',
  },
  variants: {
    short: {
      borderTopWidth: 0,
      maxWidth: '40px',
      opacity: 0.5,
    },
    light: {
      opacity: 0.2,
    },
  },
});
