import React from 'react';

import dynamic from 'next/dynamic';
import { event } from 'react-fullstory';

const FullStoryWithNoSSR = dynamic(() => import('react-fullstory'), {
  ssr: false,
});

type FSEvent = {
  name: string;
  properties?: { [key: string]: any };
};

export const fsEvent = ({ name, properties }: FSEvent): void =>
  event(name, properties);

export default function FullStory() {
  return (
    <FullStoryWithNoSSR
      debug
      namespace='FS'
      org={`${process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID}`}
    />
  );
}
