import { ImageProps } from '@chakra-ui/react';

import { FadeInImage as ElemImage } from '@/elements';
import { Image as TImage } from '@/types.d';

type ImageComponent = (
  props: ImageProps & {
    content: TImage;
  }
) => JSX.Element;

const Image: ImageComponent = ({ content, ...rest }) => {
  return (
    <ElemImage
      {...rest}
      alt={content.alt}
      src={content.filename}
      title={content.title}
    />
  );
};

export default Image;
