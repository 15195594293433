export default {
  env: process.env.NODE_ENV,
  fallbackDomain: process.env.NEXT_PUBLIC_FALLBACK_DOMAIN,
  useFallbackDomain: process.env.NEXT_PUBLIC_USE_FALLBACK_DOMAIN === 'true',
  googleAnalytics: {
    id: process.env.NEXT_PUBLIC_GA_ID,
  },
  gtm: {
    id: process.env.NEXT_PUBLIC_GTM_ID,
  },
  mixpanel: {
    token: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
  },
  amplitude: {
    id: process.env.NEXT_PUBLIC_AMPLITUDE_ID,
  },
  storyblok: {
    token: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
    version: process.env.NEXT_PUBLIC_STORYBLOK_VERSION,
    pages: {
      homeId: 38616190,
    },
    folders: {
      root: 0,
      product: 38552078,
      blog: 38245779,
      events: 39211064,
      forms: 39211061,
      careers: 39211067,
      legals: 38996839,
    },
  },
  hubspot: {
    portalId: process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID,
  },
  prodDomain: process.env.NEXT_PUBLIC_PROD_DOMAIN,
  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  },
  socialMeta: {
    baseUrl: process.env.NEXT_PUBLIC_SOCIALMETA_BASEURL,
    title: process.env.NEXT_PUBLIC_SOCIALMETA_TITLE,
    description: process.env.NEXT_PUBLIC_SOCIALMETA_DESCRIPTION,
    keywords: process.env.NEXT_PUBLIC_SOCIALMETA_KEYWORDS,
    imageUrl: process.env.NEXT_PUBLIC_SOCIALMETA_IMAGEURL,
    companyName: process.env.NEXT_PUBLIC_SOCIALMETA_COMPANYNAME,
    twitterUser: process.env.NEXT_PUBLIC_SOCIALMETA_TWITTERUSER,
  },
  title: 'Kuinua',
  tileColor: '#5849E0',
  firebaseConfig:
    process.env.NEXT_PUBLIC_FIREBASE_CONFIG &&
    JSON.parse(
      Buffer.from(
        `${process.env.NEXT_PUBLIC_FIREBASE_CONFIG}`,
        'base64'
      ).toString()
    ),
};

export const privateConfig = {
  storyblok: {
    spaceId: process.env.NEXT_PUBLIC_STORYBLOK_SPACE_ID,
    managementApiKey: process.env.STORYBLOK_MANAGEMENT_API_KEY,
  },
};
