import {
  Tabs as ChakraTabs,
  TabList as ChakraTabList,
  Tab as ChakraTab,
  TabPanels as ChakraTabPanels,
  TabPanel as ChakraTabPanel,
} from '@chakra-ui/react';

export const Tabs = ChakraTabs;
export const TabList = ChakraTabList;
export const Tab = ChakraTab;
export const TabPanels = ChakraTabPanels;
export const TabPanel = ChakraTabPanel;
