import { Heading as ChakraHeading, Text as ChakraText } from '@chakra-ui/react';

import { extendComponent } from '@/lib/utils/chakra';

const extendHeading = extendComponent(ChakraHeading);

export const Heading = extendHeading();
const extendText = extendComponent(ChakraText);

export const H1 = extendHeading({
  as: 'h1',
  fontSize: { base: 'lg', md: 'xl', lg: 'xxl' },
  lineHeight: { base: 'lg', md: 'xl', lg: 'xxl' },
});
export const H2 = extendHeading({
  as: 'h2',
  fontSize: { base: 'md', md: 'lg', lg: 'xl' },
  lineHeight: { base: 'md', md: 'lg', lg: 'xl' },
});
export const H3 = extendText({
  as: 'h3',
  fontSize: { base: 'md', md: 'md', lg: 'md' },
  lineHeight: { base: 'md', md: 'md', lg: 'lg' },
});
export const H4 = extendText({
  as: 'h4',
  fontSize: { base: 'sm', md: 'md' },
  lineHeight: { base: 'sm', md: 'md' },
});
export const H5 = extendText({
  as: 'h5',
  fontSize: { base: 'sm', md: 'base' },
  lineHeight: { base: 'sm', md: 'base' },
});
export const H6 = extendText({
  as: 'h6',
  fontSize: 'sm',
  lineHeight: 'sm',
});

// Text

const textStyle = {
  fontFamily: 'body',
};

export const Text = extendText({
  ...textStyle,
});
export const P = extendText({
  ...textStyle,
  as: 'p',
});
