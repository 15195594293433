import {
  List as ChakraList,
  ListItem,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react';

export const List = ChakraList;
export const Ol = OrderedList;
export const Ul = UnorderedList;
export const Li = ListItem;
