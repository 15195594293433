import { FC } from 'react';

import { HStack, Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

const Circle: any = styled(Box)`
  border: 1px solid transparent;
  border-radius: 50%;
  border-width: ${({ size, isActive }) => (isActive ? '2px' : `${size / 2}px`)};
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.brand.purple : theme.colors.brand.lightMedium};
  border-color: ${({ theme }) => theme.colors.brand.lightMedium};
  cursor: pointer;
  transition: all 0.4s ease-out;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

export const Dot: FC<{
  size?: number;
  active: boolean;
  onClick?: Function;
}> = ({ size = 8, active = false, onClick }) => (
  <Circle isActive={active} onClick={onClick} size={size} />
);

export const Dots: FC<{
  active: number;
  onChange: Function;
  length: number;
  size?: number;
}> = ({ active, onChange, length, size }) =>
  length > 1 && (
    <HStack display='inline-flex'>
      {new Array(length).fill(null).map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={i}>
          <Dot active={i === active} onClick={() => onChange(i)} size={size} />
        </Box>
      ))}
    </HStack>
  );
