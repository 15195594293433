export const log = message => {
  // eslint-disable-next-line no-console
  console.log(message);
};
export const info = message => {
  // eslint-disable-next-line no-console
  console.info(message);
};
export const warn = message => {
  // eslint-disable-next-line no-console
  console.warn(message);
};
export const error = message => {
  // eslint-disable-next-line no-console
  console.error(message);
};
