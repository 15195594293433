import { Box } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

// eslint-disable-next-line import/prefer-default-export
export const ShadowBox = props => (
  <Box
    {...props}
    css={theme => ({
      boxShadow: mode(theme.boxShadow.dark, theme.boxShadow.light)(props),
    })}
  />
);
