/* eslint-disable import/no-cycle */
import config from '@/config';
import { warn } from '@/services/log';

import Placeholder from '../support/Placeholder';

import Indent from './Indent';
import Table from './Table';

export const defaultBlokResolver = name => {
  if (config.env === 'development') {
    return <Placeholder name={name} />;
  }

  warn(new Error('Storyblok component has not been implemented'));
  return null;
};

export default {
  'rt-indent': props => <Indent content={props} />,
  'rt-table': props => <Table content={props} />,
};
