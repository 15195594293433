import React, { FC } from 'react';

import {
  Drawer as ChakraDrawer,
  DrawerBody as ChakraDrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter as ChakraDrawerFooter,
  DrawerOverlay,
} from '@chakra-ui/react';

import { extendComponent } from '../lib/utils/chakra';

const extendDrawerBody = extendComponent(ChakraDrawerBody);
const extendDrawerFooter = extendComponent(ChakraDrawerFooter);

export const Drawer: FC<{
  isOpen: boolean;
  onClose: () => void;
  placement: 'bottom' | 'top' | 'left' | 'right';
}> = ({ children, isOpen, onClose, placement }) => (
  <ChakraDrawer isOpen={isOpen} onClose={onClose} placement={placement}>
    <DrawerOverlay>
      <DrawerContent>
        <DrawerCloseButton />
        {children}
      </DrawerContent>
    </DrawerOverlay>
  </ChakraDrawer>
);

export const DrawerBody = extendDrawerBody({
  css: theme => ({
    a: {
      fontWeight: 'normal',
      fontSize: '0.88rem',
    },
    '.chakra-stack': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '.chakra-stack > .chakra-stack > p:first-child': {
      borderBottom: '1px solid #D8D8D8',
      fontSize: '0.88rem',
    },
    '.chakra-stack > .chakra-stack a': {
      fontWeight: 600,
    },
    hr: {
      display: 'none',
    },
    '.chakra-accordion__item': {
      width: '100%',
      border: 'none',
      borderBottom: '1px solid #D8D8D8',
      margin: 0,
    },
    '.chakra-accordion__button': {
      fontWeight: 'normal',
      justifyContent: 'center',
      fontSize: theme.fontSizes.sm,
      paddingTop: `${theme.space.xs / 4}rem`,
      paddingBottom: `${theme.space.xs / 4}rem`,
    },
  }),
});

export const DrawerFooter = extendDrawerFooter({
  css: theme => ({
    '.ctas': {
      width: '100%',
      flexWrap: 'wrap',
    },
    '.ctas a': {
      maxWidth: '45%',
      width: '100%',
    },
    '.ctas > a:nth-child(2n)': {
      textAlign: 'right',
      color: theme.colors.brand.purple,
    },
    '.ctas > a:nth-child(2n-1)': {
      textAlign: 'left',
    },
  }),
});
