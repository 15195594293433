import { forwardRef } from 'react';

import { Box, ChakraComponent, SystemStyleObject } from '@chakra-ui/react';
import merge from 'lodash/merge';

type HTMLElementName = keyof JSX.IntrinsicElements;

type ThemeMap = {
  [variantKey: string]: SystemStyleObject;
};

export type StyleConfig<
  TVariant extends ThemeMap = {},
  TSize extends ThemeMap = {}
> = {
  baseStyle?: SystemStyleObject;
  variants?: TVariant;
  sizes?: TSize;
  defaults?: {
    variant?: keyof TVariant;
    size?: keyof TSize;
  };
};

/**
 * This function styles a component and provides
 * typed variant and size options
 * @param Component The component to style
 * @param styles The base styles
 * @param overrideStyles The style variant or size overrides
 */
function createComponent<TVariant extends ThemeMap, TSize extends ThemeMap>(
  Component: HTMLElementName,
  styleConfig: StyleConfig<TVariant, TSize> = {}
): ChakraComponent<
  HTMLElementName,
  {
    variant?: keyof TVariant;
    size?: keyof TSize;
  }
> {
  const { variants, sizes, defaults, baseStyle } = styleConfig;
  // Hold default styling
  const defaultVariant = variants?.[defaults?.variant] || {};
  const defaultSize = sizes?.[defaults?.size] || {};

  // Component
  return forwardRef(
    ({ sx, variant, size, ...restProps }: any, ref): JSX.Element => {
      return (
        <Box
          ref={ref}
          as={Component}
          sx={merge(
            {},
            sx,
            baseStyle,
            variants?.[variant] || defaultVariant,
            sizes?.[size] || defaultSize
          )}
          {...restProps}
        />
      );
    }
  ) as any;
}

/**
 * A function to allow components to have a default set of props
 * already applied
 * @param Component The component to extend
 */
function extendComponent<TOriginalProps>(Component: React.FC<TOriginalProps>) {
  /**
   * A new component can be created with
   * extended by new props TPropsExtension.
   * @param baseProps The props to assign to the component by default. They can be either:
   * - An object of the original component props
   * - A function that takes the new component props and returns the origin props to apply
   */
  return function createNewComponent<TPropsExtension>(
    baseProps:
      | TOriginalProps
      | ((p: TOriginalProps & TPropsExtension) => TOriginalProps) = p => p
  ) {
    return forwardRef(
      (props: TOriginalProps & TPropsExtension, ref): JSX.Element => (
        <Component
          ref={ref}
          {...(typeof baseProps === 'function'
            ? (baseProps as any)(props)
            : baseProps)}
          {...props}
        />
      )
    );
  };
}

export { createComponent, extendComponent };
