/**
 * This library sends events through google tag manager
 * @method Send methods create a new event for any trigger
 * @method Track methods are useful for analytics and will be
 * automatically passed on to the analytics platforms (e.g. Amplitude)
 * with the correct name and data
 */

const isClient = typeof window === 'object';

export const addData = content => {
  if (isClient && window.dataLayer) {
    try {
      window.dataLayer.push(content);
    } catch (e) {
      throw new Error(`Failed to add data for GTM: ${e}`);
    }
  }
};

export const track = (
  name: string,
  data: { [key: string]: any },
  resetPrevious = true
) => {
  try {
    if (resetPrevious) addData({ data: undefined }); // reset data
    const gtmData = {
      event: 'track',
      name,
      data,
    };
    addData(gtmData);
  } catch (e) {
    throw new Error(`Failed to track ${name} for GTM: ${e}`);
  }
};
